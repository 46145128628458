<script lang="ts" setup>
import { useContentElementListStore } from "~/stores/contentElementList";

// -----------------------
// props & emits
// -----------------------
defineProps<{
  filters: Record<string, string> | null;
}>();
// -----------------------
// composables
// -----------------------
const contentElementListStore = useContentElementListStore();
const { isIOS } = useHelpers();
const { t } = useI18n();

const replaceAttributeNameIfNeeded = (name: string) => {
  if (name === "Körtúra") return "Túra típusa";
  return name;
};
</script>

<template>
  <div
    v-show="contentElementListStore.filtersLayerVisible"
    id="contentElementListFilters"
    class="fixed md:hidden top-0 max-md:left-0 max-md:w-full p-[25px] z-[1200] max-md:overflow-y-scroll max-md:bg-brand-surface mt-0"
    :class="{ ios: isIOS() }"
  >
    <div class="flex justify-between md:hidden mb-[2px]">
      <span class="">
        <i class="m-icon-filter text-[15px] mr-[5px]"></i>
        {{ t("filter") }}
      </span>

      <span
        class="text-brand-headline text-14 underline"
        @click="contentElementListStore.filtersLayerVisible = false"
        >{{ t("close") }}</span
      >
    </div>

    <CmsPageContentElementListCurrentRefinements class="max-md:mb-[2px]" />

    <template v-for="(attributeName, attribute) in filters" :key="attribute">
      <CmsPageContentElementListFacetFilter
        v-if="['a', 'b', 'c', 'd', 'e', 'f', 'i', 'j'].includes(attribute)"
        :attribute="attribute"
        :attribute-name="attributeName"
        :sort-by="['name:asc']"
        class="mb-5"
        :is-open="true"
      />
      <CmsPageContentElementListRangeFilter
        v-if="['h'].includes(attribute)"
        :attribute="attribute"
        :attribute-name="attributeName"
        data-type="distance"
        :step="1"
        class="mb-5"
        :is-open="true"
      />
    </template>
  </div>

  <div class="hidden md:block">
    <div v-for="(attributeName, attribute) in filters" :key="attribute">
      <CmsPageContentElementListFacetFilter
        v-if="['a', 'b', 'c', 'd', 'e', 'f', 'i', 'j'].includes(attribute)"
        :attribute="attribute"
        :attribute-name="replaceAttributeNameIfNeeded(attributeName)"
        :sort-by="['name:asc']"
        class="mb-5"
        :is-open="true"
      />
      <CmsPageContentElementListRangeFilter
        v-if="['h'].includes(attribute)"
        :attribute="attribute"
        :attribute-name="attributeName"
        data-type="distance"
        :step="1"
        class="mb-5"
        :is-open="true"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
$menu-height: 56px;
$filter-button-height: 44px;
$filter-button-bottom: 50px;

@media (max-width: 767px) {
  #contentElementListFilters {
    top: $menu-height;
    height: calc(100vh - $menu-height);
    padding-bottom: calc($filter-button-height + $filter-button-bottom);

    &.ios {
      padding-bottom: calc(
        $filter-button-height + $filter-button-bottom + 80px
      );
    }
  }
}
</style>
