<script setup lang="ts">
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import {
  AisConfigure,
  AisInstantSearch,
  AisHits,
} from "vue-instantsearch/vue3/es";
import { history } from "instantsearch.js/es/lib/routers";
import { singleIndex as singleIndexMapping } from "instantsearch.js/es/lib/stateMappings";

const { t } = useI18n();

// -----------------------
// props & emits
// -----------------------
const props = defineProps<{
  contentTypeId: number;
  beforeContent?: string;
  afterContent?: string;
}>();

const id = ref((Math.random() + 1).toString(36).substring(6));
const config = useRuntimeConfig();
const host = config.public.meilisearchHost;
const searchApiKey = config.public.meilisearchKey;
const options = {
  finitePagination: true,
  primaryKey: "id",
  initialFacetFilters: [`content_type_id=${props.contentTypeId}`],
};
const sortingRef = ref("updated_at:desc");
const routingRef = ref({
  router: history({
    cleanUrlOnDispose: true,
  }),
  stateMapping: singleIndexMapping(
    `${config.public.meilisearchIndexContentElements}:${sortingRef.value}`,
  ),
});
const { searchClient } = instantMeiliSearch(host, searchApiKey, options);
</script>
<template>
  <div class="container">
    <AisInstantSearch
      :id="`content-${id}`"
      class="content-element-list md:p-12"
      :routing="routingRef"
      :index-name="`${config.public.meilisearchIndexContentElements}:${sortingRef}`"
      :search-client="searchClient"
      :future="{
        preserveSharedStateOnUnmount: false,
        persistHierarchicalRootCount: false,
      }"
    >
      <slot name="default" />
      <AisConfigure
        :filters="`content_type_id = ${props.contentTypeId}`"
        :hits-per-page.camel="60"
      />
      <AisHits>
        <template #default="{ items }">
          <div class="w-full">
            <div class="w-full overflow-hidden">
              <div
                v-for="item in items"
                :key="item.id"
                class="p-4 bg-brand-secondary even:bg-brand-surface flex flex-wrap lg:flex-nowrap"
              >
                <div class="w-full lg:w-1/4 text-2xl font-lora font-bold">
                  {{ item.name }}
                </div>
                <div class="w-full lg:w-1/4">
                  <div class="font-bold">{{ t("work_location") }}</div>
                  {{ item.contentelement_lokacio_property }}
                </div>
                <div class="w-full lg:w-1/4">
                  <div class="font-bold">{{ t("work_schedule") }}</div>
                  {{ item.contentelement_foglalkoztatas_property }}
                </div>
                <div class="w-full lg:w-1/4 text-right">
                  <NuxtLink
                    :to="`/${config.public.routes.career}/${item.slug}`"
                  >
                    <BaseButton type="checkout">{{ t("details") }} </BaseButton>
                  </NuxtLink>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AisHits>
    </AisInstantSearch>
  </div>
</template>
