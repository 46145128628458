<script lang="ts" setup>
import {
  AisCurrentRefinements,
  AisClearRefinements,
} from "vue-instantsearch/vue3/es";

const { t } = useI18n();

const getItemLabel = (val: any) => {
  const parts = val.split("|");
  if (parts.length === 2) return parts[1];
  return val;
};
</script>

<template>
  <ais-current-refinements v-slot="{ items, createURL }" class="flex flex-wrap">
    <template v-for="item in items" :key="item.attribute">
      <span
        v-for="refinement in item.refinements"
        :key="
          [
            refinement.attribute,
            refinement.type,
            refinement.value,
            refinement.operator,
          ].join(':')
        "
      >
        <a
          :href="createURL(refinement)"
          @click.prevent="item.refine(refinement)"
        >
          <span
            class="inline-flex items-center gap-1.5 py-0 md:py-1.5 pl-[5px] pr-px md:pl-3 md:pr-2 rounded-full text-10 md:text-xs font-medium bg-brand-secondary mr-2.5 mb-2 text-brand-body"
          >
            {{ getItemLabel(refinement.label) }}
            <button
              type="button"
              :aria-label="refinement.label"
              class="flex-shrink-0 h-4 w-4 inline-flex items-center justify-center rounded-full text-black hover:bg-secondary00 hover:text-surface00 focus:outline-none focus:bg-secondary00 focus:text-surface00"
            >
              <span class="sr-only">{{ t("filter_delete") }}</span>
              <svg
                class="h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </button>
          </span>
        </a>
      </span>
    </template>
    <ais-clear-refinements>
      <template #default="{ canRefine, refine, createURL: createClearURL }">
        <a
          v-if="canRefine"
          :href="createClearURL()"
          class="!text-brand-headline text-12 underline ml-[16px]"
          @click.prevent="refine"
        >
          {{ t("clear_filters") }}
        </a>
      </template>
    </ais-clear-refinements>
  </ais-current-refinements>
</template>

<style scoped>
.ais-CurrentRefinements--noRefinement .ais-ClearRefinements {
  display: none;
}
</style>
