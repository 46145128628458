<script lang="ts" setup>
import { AisRefinementList } from "vue-instantsearch/vue3/es";
import { useContentElementListStore } from "~/stores/contentElementList";

const props = defineProps<{
  attribute: string;
  attributeName: string;
  sortBy?: Array<string>;
  isOpen?: boolean;
}>();

// -----------------------
// composables
// -----------------------
const contentElementListStore = useContentElementListStore();
const { t } = useI18n();

const isOpen = props.isOpen ? ref(true) : ref(false);

const getSortBy = () => {
  return typeof props.sortBy !== "undefined"
    ? props.sortBy
    : ["count:desc", "name:asc"];
};

const getItemKey = (val: any) => {
  const key = props.attribute + "_" + val;
  return key
    .toLowerCase() // Convert to lowercase
    .normalize("NFD") // Normalize diacritic characters
    .replace(/[\u0300-\u036F]/g, "") // Remove diacritic marks
    .replace(/[^\w\s]/g, "") // Remove non-word characters (except spaces)
    .replace(/\s+/g, "_"); // Replace spaces with underscores
};

const getItemLabel = (val: any) => {
  const parts = val.split("|");
  if (parts.length === 2) return parts[1];
  return val;
};
</script>

<template>
  <AisRefinementList
    v-slot="{ items, isShowingMore, canToggleShowMore, refine, toggleShowMore }"
    :attribute="props.attribute"
    :sort-by="getSortBy()"
    operator="or"
    :limit="5"
    :show-more="true"
    :show-more-limit="9999"
  >
    <div
      v-if="items.length"
      class="bg-white md:bg-brand-surface rounded-16 p-4 text-15"
    >
      <div
        class="flex flex-row cursor-pointer"
        @mousedown.prevent="isOpen = !isOpen"
      >
        <div class="basis-5/6 font-bold text-lg flex flex-row">
          <span>{{ attributeName }}</span>
        </div>
        <div
          v-if="!contentElementListStore.filtersLayerVisible"
          class="basis-1/6 text-right"
        >
          <ProductListFilterArrow :inverted="isOpen" />
        </div>
      </div>

      <div class="pt-3" :class="{ hidden: !isOpen }">
        <BaseCheckbox
          v-for="item in items"
          :key="getItemKey(item.value)"
          :value="item.isRefined"
          :label="item.label"
          :name="getItemKey(item.value)"
          :disabled="item.count === 0"
          @change="
            refine(item.value);
            useScrollElementToView('productListContainer');
          "
        >
          <BaseText tag="span">
            {{ getItemLabel(item.label) }}
          </BaseText>
        </BaseCheckbox>

        <div
          v-if="canToggleShowMore"
          class="show-more text-brand-link font-bold text-16 text-right mt-12 cursor-pointer"
          @click="toggleShowMore"
        >
          {{ !isShowingMore ? t("show_more") : t("show_less") }}
        </div>
      </div>
    </div>
  </AisRefinementList>
</template>
