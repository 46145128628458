<script lang="ts" setup>
// -----------------------
// props & emits
// -----------------------
import { viewItemList as sendViewItemListEvent } from "~/composables/useGoogleAnalytics";
import { useMeilisearch } from "~/utils/meilisearch";
import { usePriceFormatter } from "~/utils/priceFormatter";

const props = defineProps<{
  filters?: string;
  showOutOfStock?: boolean;
  title?: string;
}>();

// -----------------------
// composables
// -----------------------
const config = useRuntimeConfig();
const themeGroup = config.public.theme.group;

// -----------------------
// reactive properties
// -----------------------
const baseWidth = ref(0);
const parentElement = ref(null);
const productCards = ref(null);
const products = ref([]);

// -----------------------
// computed properties
// -----------------------
const extendedFilters = computed(() => {
  const parts = [];

  if (!props.showOutOfStock) {
    parts.push("stock > 0");
  }

  if (props.filters && props.filters.length > 0) {
    parts.push(props.filters);
  }

  return parts.join(" AND ");
});

const visibleItems = computed(() => {
  if (baseWidth.value > 1440) {
    return 5;
  } else if (baseWidth.value > 1024) {
    return 4;
  } else if (baseWidth.value > 640) {
    return 3;
  } else {
    return 2;
  }
});

const parentStyle = computed(() => {
  if (
    !productCards.value ||
    !Array.isArray(productCards.value) ||
    productCards.value.length === 0
  ) {
    return {};
  }

  const style =
    productCards.value[0].$el.currentStyle ||
    window.getComputedStyle(productCards.value[0].$el);
  const marginX = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
  const borderX =
    parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
  const totalWidth = productCards.value[0].$el.offsetWidth + marginX + borderX;
  const width = Math.min(64 + visibleItems.value * totalWidth, baseWidth.value);

  return {
    width: `${width}px`,
  };
});

// -----------------------
// helper methods
// -----------------------
const updateBaseWidth = () => {
  baseWidth.value = document.body.clientWidth;
};

// -----------------------
// vue events
// -----------------------
watch(products, () => {
  sendViewItemListEvent(products.value, { listName: props.title });
});

onMounted(updateBaseWidth);
onUpdated(updateBaseWidth);
window.addEventListener("resize", updateBaseWidth);

// -----------------------
// products load logic
// -----------------------
products.value = await useMeilisearch(
  config.public.meilisearchIndexMasterproducts,
  {
    filter: [extendedFilters.value],
  },
);
</script>

<template>
  <div
    v-if="products"
    class="flex justify-center"
    :class="{ 'bg-brand-secondary': themeGroup === 'mountex' }"
  >
    <CarouselBase
      v-if="products.length > 0"
      ref="parentElement"
      :title="title"
      :total-items="products.length"
      :scroll-arrow-size="25"
      :scroll-arrow-spacing="7"
      :infinite="true"
      class="py-[30px]"
      :style="parentStyle"
      :visible-items="visibleItems"
    >
      <ProductListProductCard
        v-for="product in products"
        ref="productCards"
        :key="product.sku"
        :product="product"
        palette=""
        class="grow-0 shrink-0 basis-[170px] md:basis-[218px] xl:basis-[250px] h-auto ml-[6px] mr-[7px] md:ml-[13px] md:mr-[13px] 2xl:ml-[12px] 2xl:mr-[12px]"
      >
        <template #name="{ url }">
          <a :href="url" class="text-black unstyled">
            <div class="font-bold font-lora text-10 md:text-12 xl:text-14">
              {{ product.masterproduct_gyarto_property }}
            </div>
            <div class="text-10 md:text-12 xl:text-14">{{ product.name }}</div>
          </a>
        </template>

        <template #price>
          <div class="flex items-end">
            <p class="mt-5 text-12 xl:text-14">
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="usePriceFormatter(product, 'masterProduct')" />
              <!-- eslint-enable -->
            </p>
          </div>
        </template>
      </ProductListProductCard>
    </CarouselBase>
  </div>
</template>
