<script lang="ts" setup>
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { AisConfigure, AisInstantSearch } from "vue-instantsearch/vue3/es";
import { history } from "instantsearch.js/es/lib/routers";
import * as Sentry from "@sentry/vue";
import { useFilterRegistry } from "~/stores/filterRegistry";
import { useContentElementListStore } from "~/stores/contentElementList";
import { useMeilisearchIndex } from "~/utils/meilisearch";

// -----------------------
// props & emits
// -----------------------
const props = defineProps<{
  contentTypeId: number;
  beforeContent?: string;
  afterContent?: string;
}>();

const id = ref();
const config = useRuntimeConfig();
const { getActiveFilters, hasActiveFilter } = useHelpers();
const host = config.public.meilisearchHost;
const searchApiKey = config.public.meilisearchKey;
const options = { finitePagination: true, primaryKey: "id" };
const filterRegistry = useFilterRegistry();
const contentElementListStore = useContentElementListStore();
const taxonMapping = ref({});
const allTaxonFetched = ref(false);
const valueSeparator = "__";
const rangeSeperator = "-";
const useMapping = config.public.taxaIdAsFilterValue === 1;
const sortingRef = ref("updated_at:desc");
const contentElementsIndex = config.public.meilisearchIndexContentElements;
const indexName = computed(() => {
  return `${contentElementsIndex}:${sortingRef.value}`;
});

if (import.meta.client) {
  id.value = ref((Math.random() + 1).toString(36).substring(6));
}

filterRegistry.filters = filterRegistry.contentElementListFilters;

const routingRef = computed(() => {
  return {
    router: history({
      cleanUrlOnDispose: true,
    }),
    stateMapping: {
      stateToRoute(uiState) {
        if (!uiState[indexName.value]) {
          return {};
        }

        const state = uiState[indexName.value];
        const result = {};

        if (state.page && state.page !== 1) {
          result.page = state.page;
        }

        if ("range" in state) {
          for (const attribute in state.range) {
            result[attribute] = state.range[attribute].replace(
              ":",
              rangeSeperator,
            );
          }
        }

        if ("refinementList" in state) {
          for (const attribute in state.refinementList) {
            const taxonomy = filterRegistry.getTaxonomy(attribute);
            const values = [];

            if (useMapping && taxonomy) {
              for (const taxa of state.refinementList[attribute]) {
                if (taxonomy in taxonMapping.value) {
                  if (taxa in taxonMapping.value[taxonomy]) {
                    values.push(taxonMapping.value[taxonomy][taxa]);
                  } else {
                    Sentry.captureEvent({
                      message: `Taxa name (${taxa}) was not found in mapping`,
                      level: "error",
                      extra: {
                        mapping: taxonMapping.value,
                      },
                    });
                  }
                } else {
                  Sentry.captureEvent({
                    message: `Taxonomy (${taxonomy}) was not found in mapping`,
                    level: "error",
                    extra: {
                      mapping: taxonMapping.value,
                    },
                  });
                }
              }
            } else {
              values.push(...state.refinementList[attribute]);
            }

            result[attribute] = values.join(valueSeparator);
          }
        }

        if ("hierarchicalMenu" in state) {
          for (const attribute in state.hierarchicalMenu) {
            result[attribute] =
              state.hierarchicalMenu[attribute].join(valueSeparator);
          }
        }

        return result;
      },
      routeToState(routeState) {
        const result = {
          [indexName.value]: {
            page: routeState.page ?? 1,
          },
        };

        for (const key in routeState) {
          const filter = filterRegistry.getFilterByAttribute(key);

          if (!filter) {
            continue;
          }

          if (filter.filterType === "hierarchical") {
            if (!result[indexName.value].hierarchicalMenu) {
              result[indexName.value].hierarchicalMenu = {};
            }

            result[indexName.value].hierarchicalMenu[key] =
              routeState[key].split(valueSeparator);
          }

          if (filter.filterType === "facet") {
            let values = routeState[key].split(valueSeparator);

            // Need to map taxa ID to name
            if (useMapping && "taxonomy" in filter) {
              if (filter.taxonomy in taxonMapping.value) {
                const taxon = taxonMapping.value[filter.taxonomy];
                values = values
                  .map((value) => {
                    const name = Object.keys(taxon).find(
                      (name) => taxon[name] === parseInt(value),
                    );

                    if (name) {
                      return name;
                    } else {
                      Sentry.captureEvent({
                        message: `Taxa (name) was not found by ID (${value})`,
                        level: "error",
                        extra: {
                          mapping: taxonMapping.value,
                        },
                      });

                      return null;
                    }
                  })
                  .filter((value) => value !== null);
              } else {
                Sentry.captureEvent({
                  message: `Taxonomy (${filter.taxonomy}) was not found in mapping`,
                  level: "error",
                  extra: {
                    mapping: taxonMapping.value,
                  },
                });
              }
            }

            if (!result[indexName.value].refinementList) {
              result[indexName.value].refinementList = {};
            }

            result[indexName.value].refinementList[key] = values;
          }

          if (filter.filterType === "range") {
            if (!result[indexName.value].range) {
              result[indexName.value].range = {};
            }

            result[indexName.value].range[key] = routeState[key].replace(
              rangeSeperator,
              ":",
            );
          }
        }

        return result;
      },
    },
  };
});
const { searchClient } = instantMeiliSearch(host, searchApiKey, options);
const { data: filterableProperties } = await useApiFetch(
  `content-type/${props.contentTypeId}/filterable-properties`,
);
// console.log(filterableProperties);

// -----------------------
// helper functions
// -----------------------
const updateTaxaMapping = (items: Record<string, any>[]) => {
  for (const item of items) {
    const externalKey = item.taxonomy_external_key;

    if (!(externalKey in taxonMapping.value)) {
      taxonMapping.value[externalKey] = {};
    }

    taxonMapping.value[externalKey][item.name] = item.id;
  }
};

const onStateChange = ({ uiState, setUiState }) => {
  if (allTaxonFetched.value) {
    setUiState(uiState);
    return;
  }

  if (indexName.value in uiState) {
    const refinementList = uiState[indexName.value].refinementList ?? {};

    if (!useMapping) {
      setUiState(uiState);
      return;
    }

    const taxonomies = Object.keys(refinementList).filter((attribute) => {
      return filterRegistry.getTaxonomy(attribute) !== null;
    });

    // Fetch taxon only if there is at least one taxonomy-based filter
    if (taxonomies.length === 0) {
      setUiState(uiState);
      return;
    }
  }

  taxaIndex
    .search("", {
      filter: `taxonomy_external_key IN [${filterRegistry.taxonomies.join(", ")}]`,
      limit: 1000,
      attributesToRetrieve: ["id", "name", "taxonomy_external_key"],
    })
    .then((response) => {
      if (response.hits) {
        updateTaxaMapping(response.hits);
        allTaxonFetched.value = true;
        setUiState(uiState);
      } else {
        Sentry.captureEvent({
          message: `Property "hits" missing from MeiliSearch response`,
          level: "error",
          extra: {
            response,
          },
        });
      }
    })
    .catch((exception) => {
      Sentry.captureEvent({
        message: `Failed to fetch taxon to build taxa mapping`,
        level: "error",
        extra: {
          exception,
        },
      });
    });
};

// ----------------------------
// load active filters' taxon
// ----------------------------
const taxaIndex = useMeilisearchIndex(config.public.meilisearchIndexTaxa);

if (useMapping && hasActiveFilter()) {
  const meilisearchFilter = [];
  const activeFilters = getActiveFilters();

  for (const attribute in activeFilters) {
    const taxonomy = filterRegistry.getTaxonomy(attribute);
    const ids = activeFilters[attribute].split(valueSeparator);

    if (taxonomy) {
      meilisearchFilter.push(
        `(taxonomy_external_key = ${taxonomy} AND id IN [${ids.join(", ")}])`,
      );
    }
  }

  const result = await taxaIndex.search("", {
    filter: meilisearchFilter.join(" OR "),
    limit: 1000,
    attributesToRetrieve: ["id", "name", "taxonomy_external_key"],
  });

  updateTaxaMapping(result.hits);
}
</script>

<template>
  <AisInstantSearch
    v-if="filterableProperties"
    :id="`content-${id}`"
    class="content-element-list md:p-12"
    :routing="routingRef"
    :index-name="indexName"
    :search-client="searchClient"
    :on-state-change="onStateChange"
    :future="{
      preserveSharedStateOnUnmount: false,
      persistHierarchicalRootCount: false,
    }"
  >
    <slot name="default" />
    <AisConfigure
      :filters="`content_type_id = ${contentTypeId}`"
      :hits-per-page.camel="60"
    />

    <!-- Active filters -->
    <CmsPageContentElementListCurrentRefinements
      class="md:col-span-4 max-md:px-[16px] md:mb-5"
    />

    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 md:col-span-3 xl:col-span-2">
        <!-- Filters -->
        <CmsPageContentElementListFilters :filters="filterableProperties" />
      </div>
      <div class="col-span-12 md:col-span-9 xl:col-span-10">
        <!-- Search results / pagination -->
        <CmsPageContentElementListSearchResults
          class="md:col-span-3"
          :before-content="beforeContent"
          :after-content="afterContent"
          :content-element-id="`content-${id}`"
        />
      </div>
    </div>

    <ProductListFilterButton
      :filters-visible="contentElementListStore.filtersLayerVisible"
      result-type="content_element.hits"
      @toggle="
        contentElementListStore.filtersLayerVisible =
          !contentElementListStore.filtersLayerVisible
      "
    ></ProductListFilterButton>
  </AisInstantSearch>
</template>
