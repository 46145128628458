<script lang="ts" setup>
import type { BackgroundImage } from "~/types/common";

interface BannerSlide {
  size?: "S" | "M" | "L";
  title?: string;
  titleColor?: string;
  subtitle?: string;
  subtitleColor?: string;
  buttonStyle?: string;
  buttonText?: string;
  buttonUrl?: string;
  backgroundImage?: BackgroundImage;
  mobileBackgroundImage?: BackgroundImage;
}

interface BannerConfiguration {
  // Previous, one-slide format, to be kept for backward compatibility
  size?: "S" | "M" | "L";
  title?: string;
  titleColor?: string;
  subtitle?: string;
  subtitleColor?: string;
  buttonStyle?: string;
  buttonText?: string;
  buttonUrl?: string;
  backgroundImage?: BackgroundImage;
  mobileBackgroundImage?: BackgroundImage;
  slides?: BannerSlide[];
}

// -----------------------
// props & emits
// -----------------------
const props = defineProps<{
  options: BannerConfiguration;
}>();

// -----------------------
// refs
// -----------------------
const currentSlideIndex = ref(0);
const changeInterval = ref(4000);
const changeIndexFn = ref();

// -----------------------
// computed properties
// -----------------------
const slides = computed(() => {
  if (
    props.options.slides &&
    Array.isArray(props.options.slides) &&
    props.options.slides.length > 0
  ) {
    return props.options.slides;
  } else {
    return [props.options];
  }
});

const multipleSlides = computed(() => {
  return slides.value.length > 1;
});

// -----------------------
// helper functions
// -----------------------
const jumpToSlide = (index: number) => {
  clearTimeout(changeIndexFn.value);
  currentSlideIndex.value = index;
  changeIndexFn.value = setTimeout(nextSlide, changeInterval.value);
};

const nextSlide = () => {
  if (!multipleSlides.value) {
    return;
  }

  currentSlideIndex.value =
    currentSlideIndex.value === slides.value.length - 1
      ? 0
      : currentSlideIndex.value + 1;

  clearTimeout(changeIndexFn.value);
  changeIndexFn.value = setTimeout(nextSlide, changeInterval.value);
};

const previousSlide = () => {
  if (!multipleSlides.value) {
    return;
  }

  currentSlideIndex.value =
    currentSlideIndex.value === 0
      ? slides.value.length - 1
      : currentSlideIndex.value - 1;

  clearTimeout(changeIndexFn.value);
  changeIndexFn.value = setTimeout(nextSlide, changeInterval.value);
};

// -----------------------
// vue events
// -----------------------
onMounted(() => {
  if (multipleSlides.value) {
    changeIndexFn.value = setTimeout(nextSlide, changeInterval.value);
  }
});
</script>

<template>
  <div :class="{ grid: multipleSlides }">
    <BannerSlide
      v-for="(slide, index) of slides"
      :key="index"
      v-touch:swipe.left="nextSlide"
      v-touch:swipe.right="previousSlide"
      :options="slide"
      :class="{
        'row-start-1 row-end-1 col-start-1 col-end-1 transition-opacity-visibility duration-1000':
          multipleSlides,
        'opacity-0 invisible': multipleSlides && index !== currentSlideIndex,
        'opacity-100': multipleSlides && index === currentSlideIndex,
      }"
    ></BannerSlide>
    <div
      v-if="multipleSlides"
      class="flex justify-center items-end z-[1000] gap-[10px] relative top-[-29px]"
    >
      <div
        v-for="(slide, index) of slides"
        :key="index"
        class="w-[12px] h-[12px] mb-[17px] rounded-full border-white border-solid border cursor-pointer"
        :class="{ 'bg-white': index === currentSlideIndex }"
        @click="jumpToSlide(index)"
      ></div>
    </div>
  </div>
</template>
